import React from 'react';
import './Loader.css'; 
import spinnerImage from '../assets/logo_v2.png';

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={spinnerImage} alt="Loading..." className="loader-image" />
      <h3>You send , we deliver ...</h3>
    </div>
  );
};

export default Loader;