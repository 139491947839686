import React from 'react';
import './Modal.css'; 

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null; 
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
      {/* <i className='bx bx-window-close close-button' onClick={onClose}></i> */}
        <div className='closebutton-box'>
        <button className="close-button" onClick={onClose}>
          X
        </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
