import React ,{useEffect ,useRef } from 'react';
import { Link } from 'react-router-dom'
import './Team.css';
import contactBg from '../../../assets/contact_us_bg.png';
import appStore from '../../../assets/app_store.png';
import googlePlay from '../../../assets/google.png'
import ScrollReveal from 'scrollreveal';
import profile from '../../../assets/card.png';
import ola from '../../../assets/ola.png';
import gaadi from '../../../assets/gaadi.png';
import ore from '../../../assets/ore.png';
import Typed from 'typed.js';


export default function OurTeam() {
   const styles = {
      container: {
        backgroundImage: `url(${contactBg})`,
        backgroundRepeat: 'no-repeat', 
        width: '100%',  
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        height: '200px',

      }
    };

    // const typedElement = useRef(null);

    useEffect(() => {
      
      ScrollReveal().reveal('.ourteam_header', {
        delay: 500,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'left'
      });
    }, []);
    useEffect(() => {
      
      ScrollReveal().reveal('.profile_img', {
        delay: 500,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'right'
      });
    }, []);

  

    // useEffect(() => {
    //   const options = {
    //     strings: ['Hello, world!', 'Welcome to my website.', 'Enjoy your stay!'],
    //     typeSpeed: 50,
    //     backSpeed: 25,
    //     loop: true
    //   };
  
    //   const typed = new Typed(typedElement.current, options);
  
    //   return () => {
      
    //     typed.destroy();
    //   };
    // }, []);
  return (
    <div>
     <div className="contact_bg" style={styles.container}></div>
     <div className="our_team_container">
        <div className="ourteam_box">
           <div className="ourteam_header">
                <h3>OUR TEAM</h3>
                <h1>We help you to achive your business goal</h1>
                {/* <p>We have created a new product that will help designers, developers and companies 
                create websites for their startups quickly and easily.</p> */}
                {/* <p>At Velox Solution Limited, we streamline logistics by offering a reliable pick-up and delivery service, enabling businesses and individuals to achieve their goals more efficiently.</p> */}
                <p>Meet the Velox Solution team, dedicated to providing seamless logistics services that help businesses and individuals achieve their goals effortlessly.</p>
                <Link to='/contact-us'> Contact Us</Link>
           </div>
           <div className="profile_img">
              <div className="profile">
                  <img src={ola} alt='profile-img' />
                  <div class="profile_info">
                     <h4>Ayansola Olawuyi</h4>
                     <p>MD/CTO</p>
                     <div class="social_media">
                       <a href="/"><i class='bx bxl-facebook'></i></a>
                       <a href="/"><i class='bx bxl-twitter'></i></a>
                       <a href="/"><i class='bx bxl-linkedin'></i></a>
                     </div>
                   </div>
              </div>
              <div className="profile">
                  <img src={ore} alt='profile-img' />
                  <div class="profile_info">
                     <h4>Akande Tabitha</h4>
                     <p>Product/Project Manager</p>
                     <div class="social_media">
                       <a href="/"><i class='bx bxl-facebook'></i></a>
                       <a href="/"><i class='bx bxl-twitter'></i></a>
                       <a href="/"><i class='bx bxl-linkedin'></i></a>
                     </div>
                   </div>
              </div>
              <div className="profile">
                  <img src={gaadi} alt='profile-img' />
                  <div class="profile_info">
                     <h4>Gaadi Praise</h4>
                     <p>Business Executive</p>
                     <div class="social_media">
                       <a href="/"><i class='bx bxl-facebook'></i></a>
                       <a href="/"><i class='bx bxl-twitter'></i></a>
                       <a href="/"><i class='bx bxl-linkedin'></i></a>
                     </div>
                   </div>
              </div>
           </div>
        </div>
     </div>
     <div className="app_section">
             <div className="app_box">
                  <div className="app_text">
                     <h3>Download Our mobile App</h3>
                     <p>Get exclusive access to logistics drivers with our mobile app. Download now and experience convenience on the go.</p>
                  </div>
                  <div className="mobile_app">
                    <img src={appStore} alt='app-store'/>
                    <img src={googlePlay} alt='google-play'/>
                  </div>
             </div>
     </div>
    </div>
  )
}
