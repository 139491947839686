import React ,{useEffect ,useRef ,useState}  from 'react'
import './Home.css';
import heroImg from '../../../assets/hero.png';
import deliveryManImg from '../../../assets/delivery_man.png';
import ScrollReveal from 'scrollreveal';
import mapLarge from '../../../assets/map-large.png';
import Typed from 'typed.js';
import airbnbImg from '../../../assets/airbnb.png';
import googleImg from '../../../assets/goole.png';
import microsoftImg from '../../../assets/microsoft.png';
import hubspotImg from '../../../assets/hubspot.png';
import fedexImg from '../../../assets/fedex.png';
import walmartImg from '../../../assets/walmart.png';
import competitiveIcon from '../../../assets/competitive.png';
import insuredIcon from '../../../assets/insured-packages.png';
import userFriendlyIcon from '../../../assets/user-friendly.png';
import reliableIcon from '../../../assets/reliable.png';
import productOne from '../../../assets/product_one.png';
import productTwo from '../../../assets/product_two.png';
import appStore from '../../../assets/app_store.png';
import googlePlay from '../../../assets/google.png'
import velox_one from '../../../assets/velox1.png';
import velox_two from '../../../assets/velox2.png';
import Modal from '../../../components/Modal/Modal';
import logo from '../../../assets/logo_v2.png';


const Home  = ()  => {

   const styles = {
      container: {
      //   height: '100vh',
        backgroundImage: `url(${mapLarge})`,
      //   backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
      }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

   useEffect(() => {
      
      ScrollReveal().reveal('.home-text', {
        delay: 300,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'bottom'
      });
    }, []);
    useEffect(() => {
      
      ScrollReveal().reveal('.hero-image', {
        delay: 300,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'top'
      });
    }, []);

    useEffect(() => {
      
      ScrollReveal().reveal('.why_choose_us_cards', {
        delay: 300,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'right'
      });
    }, []);

   
    useEffect(() => {
      
      ScrollReveal().reveal('.our_product_cards', {
        delay: 300,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'left'
      });
    }, []);
    useEffect(() => {
      
      ScrollReveal().reveal('.delivery_man_header', {
        delay: 300,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'left'
      });
    }, []);
    useEffect(() => {
      
      ScrollReveal().reveal('.delivery_man_img', {
        delay: 300,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'right'
      });
    }, []);


    

    const typedElement = useRef(null);

    useEffect(() => {
      const options = {
        strings: ['Fast Deliveries ', 'Best Delivery Prices ', 'Track Shipments ', 'Cutting-Edge Mobile App'],
        typeSpeed: 50,
        backSpeed: 25,
        loop: true
      };
  
      const typed = new Typed(typedElement.current, options);
  
      return () => {
      
        typed.destroy();
      };
    }, []);
  return (
    <div className='home'>

       <div className="home_contianer" style={styles.container}>
            <div className="home-text">
               <h3>Send and receive packages with speed and ease</h3>
                <span ref={typedElement} ></span>
                <br></br>
               {/* <p>Fast Deliveries | Best Delivery Prices | Track Shipments | Cutting-Edge Mobile App</p> */}
               <button onClick={openModal}>Get Started</button>
            </div>
            <div className="hero-image">
               <img src={heroImg} alt='hero-img'/>
            </div>
       </div>
       <div className="partners-container">
           <div className="partner_logo">
                <img src={airbnbImg} alt='logo'/>
                <img src={googleImg} alt='logo'/>
                <img src={microsoftImg} alt='logo'/>
                <img src={hubspotImg} alt='logo'/>
                <img src={fedexImg} alt='logo'/>
                <img src={walmartImg} alt='logo'/>
           </div>
       </div>
        <div className="why_choose_us-container">
            <div className="why_choose_us_header">
               <h3>WHY CHOOSE US</h3>
               <h2>Our Top Categories</h2>
               <p>Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative thinking to further the overall value proposition.</p>
            </div>
            <div className="why_choose_us_cards">
                   <div className="cards">
                        <img src={competitiveIcon} alt='icon'/>
                        <h3>Competitve Rates</h3>
                        <p>We ensure the delivery of your packages with exceptional service at the most competitive rates.</p>

                   </div>
                   <div className="cards">
                        <img src={reliableIcon} alt='icon'/>
                        <h3>Reliability</h3>
                        <p>Safe and on-time delivery, guaranteed</p>

                   </div>
                   <div className="cards">
                        <img src={insuredIcon} alt='icon'/>
                        <h3>Insured Packages</h3>
                        <p>Insure delicate and valuable items, ensuring peace of mind throughout the delivery process</p>

                   </div>
                   <div className="cards">
                        <img src={userFriendlyIcon} alt='icon'/>
                        <h3>User-friendly Interface</h3>
                        <p>Our easy-to-use platform ensures that sending your package is both easy and seamless</p>

                   </div>

            </div>
        </div>
        <div className="why_choose_us-container">
            <div className="why_choose_us_header">
               <h3>WHY CHOOSE US</h3>
               <h2>Our Products</h2>
               <p>Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative thinking to further the overall value proposition.</p>
            </div>
            <div className="our_product_cards">
                   <div className="cards">
                        <div className="productOne_text">
                           <h3>Velox For Individuals</h3>
                           <p>Do you need to send a package today with Velox and enjoy peace of mind?</p>
                           <button onClick={openModal}>Get the App</button>
                        </div>
                        <div className="product_img">
                             <img src={productOne} alt='product'/>
                        </div>
                   </div>
                   <div className="cards">
                        <div className="productOne_text">
                           <h3>Velox For Business</h3>
                           <p>Are you a business owner in need of the best delivery options to send your packages without hassle at the best rates?</p>
                           <button onClick={openModal}>Get the App</button>
                        </div>
                        <div className="product_img">
                             <img src={productTwo} alt='product'/>
                        </div>
                   </div>
            </div>
        </div>
        <div className="delivery_man_container">
              <div className="delivery_man_container">
                    <div className="delivery_man_header">
                         <h3>We deliver your package no matter the size</h3>
                         <div className="content_text">
                            <p>
                            We deliver packages of all sizes. Our extensive range of delivery options ensures that we can handle any package, 
                            no matter the size, we deliver with efficiency and reliability.
                            </p>
                         </div>
                    </div>
                    <div className="delivery_man_img">
                        <img src={deliveryManImg} alt='logo'/>
                    </div>
              </div>
            
        </div>
        <div className="why_choose_us-container">
            <div className="why_choose_us_header">
               <h3>WHY CHOOSE US</h3>
               <h2>Our Products</h2>
               <p>Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative thinking to further the overall value proposition.</p>
            </div>
            <div className="our_product_cards">
                   <div className="product-cards">
                        <div className="product-content">
                           <h3>Velox For Individuals</h3>
                           <p>Own a bike and want to join the Velox family for better earning opportunities?</p>
                           <button onClick={openModal}>Get the App</button>
                        </div>
                      
                   </div>
                   <div className="product-cards">
                        <div className="product-content">
                           <h3>Own a compnay with several delivery bikes?</h3>
                           <p>Become a Fleet Manager with Velox today! Register all your bikes with velox and enjoy higher earnings with ease.</p>
                           <button onClick={openModal}>Partner with velox</button>
                        </div>
                       
                   </div>
            </div>
        </div>
        <div className="home_app_section">
             <div className="home_app_box">
                  <div className="home_app_text">
                     <h3>Manage all projects from your mobile app⚡️</h3>
                     <p>Get exclusive access to logistics drivers with our mobile app. Download now and experience convenience on the go.</p>
                     <div className="mobile_app">
                        <p>Get the App</p>
                        <img src={appStore} alt='app-store'/>
                        <img src={googlePlay} alt='google-play'/>
                     </div>
                  </div>
                  <div className='home_app_img'>
                      <div className="app_one">
                          <div className='img_one'>
                          <img src={velox_one} alt='velox-one'/>
                          </div>
                          <div className='img_two'>
                          <img src={velox_two} alt='velox-one'/>
                          </div>
                      </div>
                     
                  </div>
             </div>
     </div>

     <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={logo} alt='logo' style={{width:'12rem'}}/>
        <h2 style={{ marginTop:'.7rem', fontWeight:'700', fontSize:'2.5rem', marginBottom:'.7rem' }}>Hello, Superstar!</h2>
        <p style={{ fontSize:'1.3rem' }}>
          We're excited to let you know that the app will be ready for download in just a few days! Stay tuned and check back soon for the launch.
        </p>
      </Modal>
    </div>

    
  )
}
export default Home;