import React ,{useEffect ,useRef} from 'react'
import './ContactUs.css';
import contactBg from '../../../assets/contact_us_bg.png';
import appStore from '../../../assets/app_store.png';
import googlePlay from '../../../assets/google.png'
import ScrollReveal from 'scrollreveal';
import Typed from 'typed.js';
import axios from 'axios';


export default function ContactUs() {
   const styles = {
      container: {
        backgroundImage: `url(${contactBg})`,
        backgroundRepeat: 'no-repeat', 
        width: '100%',  
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        height: '200px',

      }
    };

    // const typedElement = useRef(null);

    useEffect(() => {
      
      ScrollReveal().reveal('.contact_form', {
        delay: 500,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'bottom'
      });
    }, []);
    useEffect(() => {
      
      ScrollReveal().reveal('.information_box', {
        delay: 500,
        distance: '50px',
        easing: 'ease-in-out',
        origin: 'top'
      });
    }, []);

    const handleSubmit = (e) => {
      e.preventDefault();
      // setQuizSubmitted(true);

     
      // setLoading(true); 
      

      const payload = {
        'firstName' : 'Jogb',
        'lastName' : 'doe',
        'message' : 'ksss',
        'email' : 'sssss'
      }
      // axios.post('https://velox_solutions.text/api/v1/contact-us', payload)
      //     .then(response => {
      //         if (response.data.success) {
      //             setIsSubmitted(true);
      //             displaySuccess(response.data.message);

                 
      //         } else {
      //             displayError(response.data.errors);
      //         }
      //         setTimeout(() => {
      //         setLoading(false); 
      //        }, 3000);
      //     })
      //     .catch(error => {
      //         displayError('Error submitting quiz:', error);
      //         setLoading(false); 
      //     });
      //     setQuizSubmitted(false)
  };
  

    // useEffect(() => {
    //   const options = {
    //     strings: ['Hello, world!', 'Welcome to my website.', 'Enjoy your stay!'],
    //     typeSpeed: 50,
    //     backSpeed: 25,
    //     loop: true
    //   };
  
    //   const typed = new Typed(typedElement.current, options);
  
    //   return () => {
      
    //     typed.destroy();
    //   };
    // }, []);
  return (
    <div>
     <div className="contact_bg" style={styles.container}></div>
     <div className="contact_box">
      <div className="contact_form">
        <div className="contact_header">
        {/* <span ref={typedElement} /> */}
            <h3>CONTACT US </h3>
            <h2>Need Additional Information ?</h2>
            <p>Please submit your information and a Velox representative will get in touch with you</p>
        </div>
        <div className="form_box">
            <form onSubmit={handleSubmit}>
               <div className="form_field">
                  <input type='text' placeholder='Your Name'/>
               </div>
               <div className="form_field">
                  <input type='text' placeholder='Your Email' />
               </div>

               <div className="form_field">
                  <textarea placeholder='Your Message' cols='10' rows='7'></textarea>
               </div>
               <div className="contact_button">
                  <button>Submit</button>
               </div>
            </form>
        </div>
      </div>
      <div className="information_box">
        <h3>Help</h3>
        <p>Ready to start your hassle-free move? Contact Velox today for all your courier needs. Visit our website or give us a call at [+91 98765 43210]. Our friendly customer service team is always here to assist you.</p>
      </div>
     </div>
     <div className="app_section">
             <div className="app_box">
                  <div className="app_text">
                     <h3>Download Our mobile App</h3>
                     <p>Get exclusive access to logistics drivers with our mobile app. Download now and experience convenience on the go.</p>
                  </div>
                  <div className="mobile_app">
                    <img src={appStore} alt='app-store'/>
                    <img src={googlePlay} alt='google-play'/>
                  </div>
             </div>
     </div>
    </div>
  )
}
