import React, { useEffect } from 'react';

const MobileStore = () => {
  useEffect(() => {
    // Function to detect the user's OS
    const detectOS = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }

      if (/Macintosh|Windows/i.test(userAgent)) {
        return 'Desktop';
      }

      return 'Unknown';
    };

    // Function to handle redirection based on OS
    const handleRedirect = () => {
      const os = detectOS();

      switch (os) {
        case 'iOS':
          window.location.href = 'https://apps.apple.com/us/app/riseng/id6444195280?uo=4';
          break;
        case 'Android':
          window.location.href = 'https://play.google.com/store/apps/details?id=com.monarcode.riseapp';
          break;
        case 'Desktop':
          window.location.href = 'https://veloxsolution.ng/download'; // Replace with your desktop download page or another action
          break;
        default:
          console.log('OS not detected or unsupported.');
      }
    };

    // Execute the redirection
    handleRedirect();
  }, []);

  return (
    <div>
      <h1>Redirecting to the appropriate store...</h1>
    </div>
  );
};

export default MobileStore;
