import { Routes, Route , BrowserRouter as Router} from "react-router-dom";
import Layouts from "./components/shared/Layout";
import 'boxicons/css/boxicons.min.css';
import ContactUs from "./components/Pages/Contact/ContactUs";
import OurTeam from "./components/Pages/Team/OurTeam";
import Home from "./components/Pages/Home/Home";
import Loader from './components/Loader.jsx';
import React, { useState, useEffect } from 'react';
import MobileStore from "./components/Pages/MobileStore/MobileStore.jsx";

function App() {
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);
  return (
    <div className="App">
    {loading ? (
        <Loader />
      ) : (
       <Router>
        <Routes>
            <Route path="/" element={<Layouts/>} >
            <Route path="/" element={<Home/>} />
            <Route path='/contact-us' element={<ContactUs/>} />
            <Route path='/our-team' element={<OurTeam/>} />
            <Route path='/store' element={<MobileStore/>}/>
          </Route>
      </Routes>
      </Router>
      )};
    </div>
  );
}

export default App;
